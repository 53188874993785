import axios from '@/axios.js'

const state = () => ({
   tempat_lapors: []
})

const mutations = {
    SET_TEMPATLAPORS (state, payload) {
      state.tempat_lapors = payload
    },
    ADD_TEMPATLAPOR (state, item) {
      state.tempat_lapors.unshift(item)
    },
    UPDATE_TEMPATLAPOR (state, tempat_lapor) {
      const tempat_laporIndex = state.tempat_lapors.findIndex((p) => p.id === tempat_lapor.id)
      Object.assign(state.tempat_lapors[tempat_laporIndex], tempat_lapor)
    },
    REMOVE_TEMPATLAPOR (state, itemId) {
      const ItemIndex = state.tempat_lapors.findIndex((p) => p.id === itemId)
      state.tempat_lapors.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchTempatLapor ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/tempat_lapor`)
        .then((response) => {
          commit('SET_TEMPATLAPORS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addTempatLapor ({ commit }, tempat_lapor ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', tempat_lapor)
        .then((response) => {
          commit('ADD_TEMPATLAPOR', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateTempatLapor ({ commit }, tempat_lapor) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${tempat_lapor.id}`, tempat_lapor)
        .then((response) => {
            commit('UPDATE_TEMPATLAPOR', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormTempatLapor ({ commit }, tempat_lapor) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, tempat_lapor)
        .then((response) => {
            commit('UPDATE_TEMPATLAPOR', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeTempatLapor ({ commit }, tempat_lapor) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${tempat_lapor.id}`)
        .then((response) => {
            commit('REMOVE_TEMPATLAPOR', tempat_lapor.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}